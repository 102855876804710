import { useState, useEffect } from 'react';
import { Drink } from '../models/Drink';
import useInterval from './useInterval';

export function useCalcualtor(user: any, drinks: Drink[]) {
  const [userBAC, setUserBAC] = useState(0);
  const [soberTime, setSoberTime] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const getUserBac = () => {
    let allBac = 0;
    drinks.forEach(drink => {
      const { BACCurrent } = calculationFormula(user.gender, user.weight, user.height, 1, drink?.ABV || 0, drink?.unit || 0, (drink?.date || Date.now()));
      allBac += BACCurrent;
    })
    // console.log('allBac', allBac);
    setUserBAC(allBac);
  }

  useInterval(() => {
    // console.log('i: calculating...');
    getUserBac();
    // console.log('i: calculate done');
    setRefresh(Date.now())
  }, 20000, [getUserBac]);

  const calculationFormula = (
    gender: string,
    weight: number,
    height: number,
    drinkUnit: number,
    drinkAlcohol: number,
    DrinkInMilliliter: number,
    timeStartedDrink: number
  ) => {
    // @todo: height
    // @todo: DoB
    // // console.log(gender, weight, drinkUnit, drinkAlcohol, timeStartedDrink);
    // male 93 1 0.15 1517509177.359
    const MaleFactor = 0.68; // 0.68
    const FemaleFactor = 0.55; // 0.55
    const AlcholoFactor = 0.789; // 0.789
    const AlcoholAbsorption = 0.010; // 0.0015
    const AlcoholAbsorptionPerMile = AlcoholAbsorption / (60 * 60 * 1000);
    const timeStarted = timeStartedDrink;
    // const avarageConsuptionTime = 30 * 60 * 1000; // 1800s half an hour
    // ----- //
    let BACMax = 0;
    let BACCurrent = 0;
    const weightInGramm = weight * 1000;
    const weightInGender = weightInGramm * (gender === 'male' ? MaleFactor : FemaleFactor);
    const drinkInGramm = drinkUnit * (drinkAlcohol * 100) * DrinkInMilliliter * AlcholoFactor;

    const calculatedBAC = drinkInGramm / weightInGender;
    const now = Math.floor(new Date().getTime());

    const elpisedTime = timeStarted - Math.floor(now);
    // let dateFactorMax = 0;
    let dateFactorCurrent = 0;
    // if (elpisedTime >= -avarageConsuptionTime) {
    // dateFactorMax = (elpisedTime + avarageConsuptionTime) / avarageConsuptionTime;
    BACMax = calculatedBAC;
    // } else {
    dateFactorCurrent = elpisedTime * AlcoholAbsorptionPerMile;
    BACCurrent = calculatedBAC + dateFactorCurrent;
    // }

    BACCurrent = Number(BACCurrent.toFixed(8));
    BACMax = Number(BACMax.toFixed(8));

    return {
      BACCurrent: (BACCurrent > 0) ? BACCurrent : 0,
      BACMax: (BACMax > 0) ? BACMax : 0
    };
  }



  const displayHours = (percentage: number) => {
    return Date.now() + (1000 * 60 * (60 * Math.floor(percentage / 0.015)));
  }

  useEffect(() => {
    const sober = displayHours(userBAC);
    // // console.log('refresh', sober);
    setSoberTime(sober);
  }, [drinks, refresh, userBAC]);

  return {
    userBAC,
    soberTime,
    setRefresh
  }
}