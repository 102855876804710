import React from 'react';
import { IonFooter, IonToolbar, IonTitle, IonFab, IonFabButton, IonIcon, IonButtons, IonButton, IonModal } from '@ionic/react';
import { add, triangle, square } from 'ionicons/icons';
import Search from './Search';
import { useState } from 'react';
import { addDrink, removeDrink } from '../data/user/user.actions';
import { connect } from '../data/connect';


interface DispatchProps {
  addDrink: typeof addDrink;
  removeDrink: typeof removeDrink;
}

interface NavigationProps extends DispatchProps { }

const Navigation: React.FC<NavigationProps> = ({ addDrink, removeDrink }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <IonFab vertical="bottom" horizontal="center">
        <IonFabButton color="success" onClick={() => setShowModal(true)}>
          <IonIcon color="dark" icon={add} />
        </IonFabButton>
      </IonFab>
      {/* <h1 className="ion-text-center">Sydy <IonBadge>Beta {appVersion}</IonBadge></h1> */}
      <IonFooter className="">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/kalkulator">
              <IonIcon slot="start" icon={triangle} />
              Kalkulator
            </IonButton>
          </IonButtons>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/beallitas">
              <IonIcon slot="start" icon={square} />
              Beallitas
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <IonModal isOpen={showModal}>
        <Search addDrink={addDrink} hideModal={() => setShowModal(false)} />
      </IonModal>
    </>
  );
};

export default connect<DispatchProps>({
  mapDispatchToProps: {
    addDrink,
    removeDrink,
  },
  component: Navigation
})