import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setupConfig } from '@ionic/react';
import * as Sentry from '@sentry/browser';
import packageJson from '../package.json';
// import Amplify from "aws-amplify";


const isProd = process.env.NODE_ENV === 'production';
Sentry.init({
  dsn: "https://0d2aaf8485a94bc7864f9576976ffb46@o206862.ingest.sentry.io/5216081",
  release: `${packageJson.name} ${packageJson.version}`,
  environment: (isProd) ? 'production' : 'development',
  debug: isProd
});

// const analyticsConfig = {
//   AWSPinpoint: {
//     // Amazon Pinpoint App Client ID
//     appId: '58b80e467e2440ef81cb136a1a2f61aa',
//     // Amazon service region
//     region: 'eu-central-1',
//     mandatorySignIn: false,
//   }
// }

// Amplify.configure(analyticsConfig);

defineCustomElements(window);

setupConfig({
  rippleEffect: false,
  mode: 'md'
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
