import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Settings from './pages/Settings';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global CSS */
import './global.css';
import { AppContextProvider } from './data/AppContext';
import { loadUserData, setIsLoggedIn, setUsername } from './data/user/user.actions';
import { useEffect } from 'react';
import { connect } from './data/connect';
import About from './pages/About';

// import { withAuthenticator } from '@aws-amplify/ui-react'
// import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import ErrorBoundary from './sentry/ErrrorBoundary';
import { isPlatform } from '@ionic/react';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AppContextProvider>
        <IonicAppConnected />
      </AppContextProvider>
    </ErrorBoundary>
  );
};

interface StateProps {
  darkMode: boolean;
  loading: boolean;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, loadUserData, loading }) => {

  useEffect(() => {
    loadUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          color: 'white',
          textAlign: 'center',
          transform: "translate(-50%, -50%)",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="135" height="140" viewBox="0 0 135 140" fill="#fff">
          <rect y="20.9981" width="15" height="98.0037" rx="6">
            <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
          </rect>
          <rect x="30" y="33.4981" width="15" height="73.0037" rx="6">
            <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
          </rect>
          <rect x="60" width="15" height="48.0037" rx="6" y="45.9981">
            <animate attributeName="height" begin="0s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
          </rect>
          <rect x="90" y="33.4981" width="15" height="73.0037" rx="6">
            <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
          </rect>
          <rect x="120" y="20.9981" width="15" height="98.0037" rx="6">
            <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
            <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
          </rect>
        </svg>
        <h1>Sydy</h1>
      </div> :
        <IonReactRouter>
          <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
            <Switch>
              {isPlatform('capacitor') ? <Redirect exact from="/" to="kalkulator" /> : <Redirect exact from="/" to="welcome" />}
              <Route exact path="/welcome" component={About} />
              <IonRouterOutlet>
                <Route path="/kalkulator" component={Home} />
                <Route path="/beallitas" component={Settings} />
                <Route exact path="/logout" render={() => {
                  setIsLoggedIn(false);
                  setUsername(undefined);
                  return <Redirect to="/" />
                }} />
              </IonRouterOutlet>
            </Switch>
          </IonApp>
        </IonReactRouter>
      }
    </>
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    loading: state.user.loading,
  }),
  mapDispatchToProps: { loadUserData, setIsLoggedIn, setUsername },
  component: IonicApp
});