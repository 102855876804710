// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:55383c69-f34a-4d01-8ca2-da32bb69a8db",
    "aws_cognito_region": "eu-west-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "e0ab52f22ec349dfa9e3c80e9413796f",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
