import { combineReducers } from './combineReducers';
import { userReducer } from './user/user.reducer';


export const initialState: AppState = {
  user: {
    hasSeenTutorial: false,
    darkMode: false,
    height: 0,
    weight: 0,
    age: 0,
    gender: 'man',
    isLoggedin: false,
    loading: false,
    drinks: []
  }
};

export const reducers = combineReducers({
  user: userReducer
});

export type AppState = ReturnType<typeof reducers>;