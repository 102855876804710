import React from 'react'
import { IonCard, IonCardContent } from '@ionic/react';

export default function SummaryCard(props: any) {
  const drinks: any = props.drinks || [];
  return (
    (drinks.length) ?
      <IonCard color="tertiary">
        <IonCardContent className="ion-text-center">
          Elfogyasztott
          <h1>{drinks.length} ital</h1>
        </IonCardContent>
      </IonCard> : null
  )
}
