import React from 'react';
import './About.css';
import { IonPage, IonContent } from '@ionic/react';
import packageJson from '../../package.json';
import { Link } from 'react-router-dom';

const About: React.FC = () => {
  const appVersion = packageJson.version || '';

  return (
    <IonPage>
      <IonContent>
        <div className="about-sydy">
          <div className="overlay"></div>

          <div className="wrap">

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>
                    <span>say hello</span>
                    <span className="green dots">.</span>
                    <br />We are working <span className="green subtitle">hard!</span>
                  </h1>

                  <p>We work to improve <strong>our website</strong> and make it look fresher! <br /> But we'll be back!</p>

                  <div className="is-countdown defaultCountdown"></div>
                  <p className="copyright">Created by <a href="https://davidbreuer.co.uk/" rel="noopener noreferrer" target="_blank">WebSteel
                            Ltd.</a>
                    <br />
                    <Link to="/kalkulator" className="mute"><small>{appVersion}</small></Link>
                  </p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
