import { getUserData, setIsLoggedInData, setUsernameData, setHasSeenTutorialData, addDrinks, deleteDrink, deleteAll, setUserProfile } from '../dataApi';
import { ActionType } from '../../util/types';
import { UserState } from './user.state';
import { Drink } from '../../models/Drink';
import { v4 as uuidv4 } from 'uuid';

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  dispatch(setData(data));
  dispatch(setLoading(false));
}

export const setLoading = (isLoading: boolean) => ({
  type: 'set-user-loading',
  isLoading
} as const);

export const setData = (data: Partial<UserState>) => ({
  type: 'set-user-data',
  data
} as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
  dispatch(setUsername());
};

export const setIsLoggedIn = (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(loggedIn);
  return ({
    type: 'set-is-loggedin',
    loggedIn
  } as const)
};

export const setUsername = (username?: string) => async (dispatch: React.Dispatch<any>) => {
  await setUsernameData(username);
  return ({
    type: 'set-username',
    username
  } as const);
};

export const setHasSeenTutorial = (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenTutorialData(hasSeenTutorial);
  return ({
    type: 'set-has-seen-tutorial',
    hasSeenTutorial
  } as const);
}

export const setDarkMode = (darkMode: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setUserProfile({ darkMode })
  return ({
    type: 'set-dark-mode',
    darkMode
  } as const);
}

export const setUserHeight = (height: number) => async (dispatch: React.Dispatch<any>) => {
  await setUserProfile({ height })
  return ({
    type: 'set-user-height',
    height
  } as const);
}

export const setUserWeight = (weight: number) => async (dispatch: React.Dispatch<any>) => {
  await setUserProfile({ weight })
  return ({
    type: 'set-user-weight',
    weight
  } as const);
}

export const setUserAge = (age: number) => async (dispatch: React.Dispatch<any>) => {
  await setUserProfile({ age })
  return ({
    type: 'set-user-age',
    age
  } as const);
}

export const setUserGender = (gender: string) => async (dispatch: React.Dispatch<any>) => {
  await setUserProfile({ gender })
  return ({
    type: 'set-user-gender',
    gender
  } as const);
}

export const addDrink = (drinkToAdd: Drink, isnew?: boolean) => async (dispatch: React.Dispatch<any>) => {
  drinkToAdd.guid = uuidv4();
  drinkToAdd.date = Date.now();
  const drink = { ...drinkToAdd };
  await addDrinks([drink]);
  return ({
    type: 'add-drink',
    drink
  } as const);
}

export const removeDrink = (drink: Drink) => async (dispatch: React.Dispatch<any>) => {
  await deleteDrink(drink);
  return ({
    type: 'remove-drink',
    drink
  } as const);
}

export const clearAll = () => async (dispatch: React.Dispatch<any>) => {
  await deleteAll();
  return ({
    type: 'clear-all'
  } as const);
}

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUsername>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setUserHeight>
  | ActionType<typeof setUserWeight>
  | ActionType<typeof setUserAge>
  | ActionType<typeof setUserGender>
  | ActionType<typeof addDrink>
  | ActionType<typeof removeDrink>
  | ActionType<typeof clearAll>