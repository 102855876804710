

import React from 'react';
import { IonList, IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption, IonNote, IonIcon } from '@ionic/react';
import { trendingUp, trash, repeat, trendingDown, remove } from 'ionicons/icons';
import { formatRelative, subDays, differenceInHours } from "date-fns";
import { hu } from 'date-fns/locale'
import { addDrink, removeDrink } from '../data/user/user.actions';
import { Drink } from '../models/Drink';

interface DrinkList {
  items?: Drink[];
  limit: number;
  addAgain: typeof addDrink;
  removeItem: typeof removeDrink;
}

const getDrinkTrend = (drink: Drink) => {
  const diffHours = (drink.date) ? differenceInHours(Date.now(), new Date(drink.date)) : 0;
  return (diffHours > 0) ? (diffHours > 12) ? 1 : -1 : 0
}

const DrinkList: React.FC<DrinkList> = ({ items, limit, addAgain, removeItem }) => {
  return (
    <IonList className={"drink-list"}>
      {
        (items?.length) ? items.sort((a: any, b: any) => b.date - a.date).slice(0, limit).map((item: Drink) => {
          return <IonItemSliding key={item.guid} >
            <IonItem lines="full" className={'type-' + item.type?.replace(' ', '-').toLowerCase()}>
              <IonIcon slot="start" color={(getDrinkTrend(item) > -1) ? (getDrinkTrend(item) > 0) ? 'default' : 'secondary' : 'success'} icon={(getDrinkTrend(item) > -1) ? (getDrinkTrend(item) > 0) ? remove : trendingUp : trendingDown}></IonIcon>
              <IonLabel>
                <h3>{item.name}</h3>
                {(item.date) ? <p>{item.type} - {formatRelative(subDays(item.date, 0), new Date(), { locale: hu })}</p> : null}
              </IonLabel>
              <IonNote slot="end">
                {item?.unit}ml<br />
                {item?.ABV ? <small>{new Intl.NumberFormat('en-GB', { style: 'percent', maximumFractionDigits: 2 }).format(item?.ABV)}</small> : null}
              </IonNote>
            </IonItem>
            {(item) ? <IonItemOptions side="end">
              <IonItemOption type="button" color="tertiary" onClick={() => addAgain({ ...item })}>
                Ismét
                <IonIcon slot="end" icon={repeat}></IonIcon>
              </IonItemOption>
              <IonItemOption color="danger" onClick={() => removeItem(item)} >
                Töröl
                <IonIcon slot="end" icon={trash}></IonIcon>
              </IonItemOption>
            </IonItemOptions> : null}
          </IonItemSliding>
        }) : <IonItem>Nincs ital</IonItem>
      }
    </IonList>
  );
};

export default DrinkList;
