import { UserActions } from './user.actions';
import { UserState } from './user.state';
import { Drink } from '../../models/Drink';
import { initialState } from '../state';

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading };
    case 'set-user-data':
      return { ...state, ...action.data };
    case 'set-username':
      return { ...state, username: action.username };
    case 'set-has-seen-tutorial':
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode };
    case 'set-user-height':
      return { ...state, height: action.height };
    case 'set-user-weight':
      return { ...state, weight: action.weight };
    case 'set-user-age':
      return { ...state, age: action.age };
    case 'set-user-gender':
      return { ...state, gender: action.gender };
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.loggedIn };
    case 'add-drink':
      return { ...state, drinks: [...state.drinks, action.drink] };
    case 'remove-drink':
      return { ...state, drinks: [...state.drinks.filter((item: Drink) => item.guid !== action.drink.guid)] };
    case 'clear-all':
      return { ...initialState.user };
  }
}