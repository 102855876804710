import { Plugins } from '@capacitor/core';
import { Drink } from '../models/Drink';
import { v4 as uuidv4 } from 'uuid';

import { Analytics } from 'aws-amplify';
import Amplify from 'aws-amplify';
import awsConfig from '../aws-exports';

Amplify.configure(awsConfig);

const isDebug = process.env.NODE_ENV !== 'production';

const { Storage } = Plugins;

const HAS_LOGGED_IN = 'hasLoggedIn';
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
const USERNAME = 'username';
const DRINK_LIST = 'drinks';
const PROFILE = 'profile';
const USER_ID = 'uuid';


export const getDrinkData = async () => {
  const response: any = await Storage.get({ key: DRINK_LIST });
  const drinks = JSON.parse(response.value) || [];
  const data = {
    drinks
  };
  return data;
}

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: USERNAME }),
    Storage.get({ key: DRINK_LIST }),
    Storage.get({ key: PROFILE }),
    Storage.get({ key: USER_ID }),
  ]);
  const isLoggedin = await response[0].value === 'true';
  const hasSeenTutorial = await response[1].value === 'true';
  const username = await response[2].value || undefined;
  const drinks = await response[3].value || undefined;
  const profile = await response[4].value || undefined;
  const userId = await response[5].value || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
    drinks: (drinks) ? JSON.parse(drinks as any) : [],
    darkMode: (profile) ? JSON.parse(profile as any).darkMode : null,
    height: (profile) ? JSON.parse(profile as any).height : null,
    weight: (profile) ? JSON.parse(profile as any).weight : null,
    age: (profile) ? JSON.parse(profile as any).age : null,
    gender: (profile) ? JSON.parse(profile as any).gender : null,
    userId
  }
  return data;
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  const hasUUID = await Storage.get({ key: USER_ID });
  if (!hasUUID.value) {
    const newUserUUID = uuidv4();
    Storage.set({ key: USER_ID, value: newUserUUID });
    await sendAnalytics('Create user');
  }
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
}

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await sendAnalytics('Tutorial seen');
  await Storage.set({ key: HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) });
}

export const setUsernameData = async (username?: string) => {

  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
}

export const setUserProfile = async (profile?: any) => {
  const userProfile = await Storage.get({ key: PROFILE });
  const updatedProfile = (userProfile.value) ? { ...JSON.parse(userProfile.value as any), ...profile } : { ...profile };
  // await sendAnalytics('Update profile', { ...updatedProfile });
  await Storage.set({ key: PROFILE, value: JSON.stringify(updatedProfile) });
}

export const addDrinks = async (drinks: Drink[]) => {
  const currentList = await Storage.get({ key: DRINK_LIST });
  const list = (currentList.value) ? JSON.parse(currentList.value as any) : [];
  const newList = [...list, ...drinks];
  await sendAnalytics('Add drink', { ...drinks[0] });
  await Storage.set({ key: DRINK_LIST, value: JSON.stringify(newList) });
}

export const deleteDrink = async (drink: Drink) => {
  const currentList = await Storage.get({ key: DRINK_LIST });
  const list = (currentList.value) ? JSON.parse(currentList.value as any) : [];
  const filteredList = list.filter((itemToRemove: Drink) => itemToRemove.guid !== drink.guid);
  await sendAnalytics('Removed drink', { ...drink });
  await Storage.set({ key: DRINK_LIST, value: JSON.stringify(filteredList) });
}

export const deleteAll = async () => {
  const response = await Promise.all([
    Storage.remove({ key: HAS_LOGGED_IN }),
    Storage.remove({ key: HAS_SEEN_TUTORIAL }),
    Storage.remove({ key: USERNAME }),
    Storage.remove({ key: DRINK_LIST }),
    Storage.remove({ key: PROFILE }),
  ]);
  return response;
}

const sendAnalytics = async (eventName: string, attributes: any = {}, metrics: any = {}) => {
  const hasUUID = await Storage.get({ key: USER_ID });
  if (isDebug) {
    console.log('[DEBUG] ', { name: eventName, attributes: { userId: hasUUID.value, ...attributes }, metrics })
  } else {
    Analytics.record({ name: eventName, attributes: { userId: hasUUID.value, ...attributes }, metrics })
  }
}
