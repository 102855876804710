
import React from 'react'
import { IonCard, IonCardContent } from '@ionic/react';
// import Countdown from 'react-countdown';
import { useCalcualtor } from '../hooks/useCalculator';
import { connect } from '../data/connect';
import { useEffect, useState } from 'react';
import { formatRelative, subDays } from 'date-fns';
import { hu } from 'date-fns/locale'

// const Completionist = () => <h2>You are good to go!</h2>;
interface StateProps {
  height?: number;
  weight?: number;
  age?: number;
  gender?: string;
  drinks?: any;
}

const percentColors = [
  { pct: 0.0, color: { r: 0x00, g: 0x96, b: 0 } },
  { pct: 1.5, color: { r: 0xff, g: 0x98, b: 0 } },
  { pct: 2.5, color: { r: 0xff, g: 0x00, b: 0 } }];

const ProgressCard: React.FC<StateProps> = ({ height, weight, age, gender, drinks }) => {
  const { userBAC, soberTime } = useCalcualtor({ height, weight, age, gender }, drinks);
  const [progressLevel, setProgressLevel] = useState(0)
  // Renderer callback with condition

  // useEffect(() => {
  //   setRefresh(Date.now());
  // }, [height, drinks]);

  useEffect(() => {
    const normBac: number = userBAC * 10;
    setProgressLevel(normBac);
  }, [userBAC])

  const getColorForPercentage = (pct: number) => {
    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
  }

  return (
    (soberTime && userBAC) ?
      <IonCard style={{ color: 'white', backgroundColor: getColorForPercentage(progressLevel) }}>
        <IonCardContent className="ion-text-center">
          {formatRelative(subDays(soberTime, 0), new Date(), { locale: hu })}

          {(userBAC > 0) ? <h1>{userBAC.toFixed(4)}%</h1> : null}

        </IonCardContent>
      </IonCard> : null
  )
}

export default connect<StateProps>({
  mapStateToProps: (state) => ({
    height: state.user.height,
    weight: state.user.weight,
    age: state.user.age,
    gender: state.user.gender,
    drinks: state.user.drinks,
  }),
  component: ProgressCard
})
