import React from "react";
import * as Sentry from '@sentry/browser';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.captureException(error);
  }
  render() {
    if (this.state.error) {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            color: 'white',
            textAlign: 'center',
            transform: "translate(-50%, -50%)",
          }}
          className="snap"
          onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
        >
          <h3>We're sorry — something's gone wrong.</h3>
          <p>Our team has been notified, but click here fill out a report.</p>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;