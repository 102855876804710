import React from 'react'
import { InstantSearch, Hits } from 'react-instantsearch-dom';
import { IonList, IonItem, IonLabel, IonSearchbar, IonButton, IonContent, IonButtons, IonIcon, IonToolbar, IonListHeader, IonPopover, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import algoliasearch from 'algoliasearch/lite';
import { connectSearchBox, Stats } from 'react-instantsearch-dom';
import { addDrink } from '../data/user/user.actions';
import { Drink } from '../models/Drink';
// import { isPlatform } from '@ionic/react';
import './Search.css';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { barcode, close } from 'ionicons/icons';
import { useState } from 'react';

interface SearchCompProps {
  addDrink: typeof addDrink;
  hideModal: any;
}

const unitList: { [key: string]: any[] } = {
  beer: [
    {
      name: 'picolo',
      value: 200
    },
    {
      name: 'glass',
      value: 300
    },
    {
      name: 'jug',
      value: 400
    },
    {
      name: 'jug_2',
      value: 500
    },
    {
      name: 'bottle',
      value: 330
    },
    {
      name: 'bottle_2',
      value: 500
    },
    {
      name: 'pint',
      value: 568
    }
  ],
  shot: [
    {
      name: 'small',
      value: 20
    },
    {
      name: 'single',
      value: 25
    },
    {
      name: 'large',
      value: 40
    },
    {
      name: 'double',
      value: 50
    },
    {
      name: 'big',
      value: 100
    }
  ],
  wine: [
    {
      name: 'small_wine',
      value: 100
    },
    {
      name: 'middle_wine',
      value: 150
    },
    {
      name: 'big_wine',
      value: 200
    },
    {
      name: 'large_wine',
      value: 300
    }
  ],
  champagne: [
    {
      name: 'champagne_small',
      value: 100
    },
    {
      name: 'champagne_medium',
      value: 150
    },
    {
      name: 'champagne_large',
      value: 200
    }
  ],
  longdrink: [
    {
      name: 'small_wine',
      value: 100
    },
    {
      name: 'middle_wine',
      value: 150
    },
    {
      name: 'big_wine',
      value: 200
    },
    {
      name: 'large_wine',
      value: 300
    }
  ]
};

const searchClient = algoliasearch('NJOV4IU84X', '53b4a90f9ff0c202c9b83b7018aae0c6');
// const showBarcode = isPlatform('mobile');
const showBarcode = false;
const SearchBoxCustom: React.FC<any> = ({ currentRefinement, refine, hideModal }) => {
  const openScanner = async () => {
    const data = await BarcodeScanner.scan();
    console.log(`Barcode data: ${data.text}`);
    refine(data.text)
  };
  return (
    <IonToolbar>
      <IonSearchbar style={{ marginTop: 15 }} placeholder="Ital neve, tipusa vagy kodja..." value={currentRefinement} showCancelButton="never" onIonChange={event => refine(event.detail.value)} />
      {
        (showBarcode) ? <IonButtons slot="secondary">
          <IonButton onClick={openScanner}>
            <IonIcon slot="icon-only" icon={barcode} />
          </IonButton>
        </IonButtons> : null}
      <IonButtons style={{ marginTop: 15 }} slot="secondary">
        <IonButton onClick={() => hideModal()} >
          <IonIcon slot="icon-only" icon={close} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  )
};
const Search: React.FC<SearchCompProps> = ({ addDrink, hideModal }) => {
  // const [searchType, setSearchType] = useState<string>('kereso');
  // const [customHit, setCustomHit] = useState<any>();
  const [customUnit, setCustomUnit] = useState<{ value: number; type: string }>({ value: 0, type: 'ml' });
  const [showMesurement, setShowMesurement] = useState<boolean>(false);
  const addMesurment = (item: Drink, unit: number) => {
    item.unit = unit;
    addDrink(item);
    hideModal();
  }
  // const updateSegment = (value?: string) => {
  //   if (value) {
  //     setSearchType(value);
  //   }
  // }

  // const customMesurementHit = (hit: any) => {
  //   if (customUnit.value) {
  //     addMesurment(hit, customHit.value);
  //   } else {

  //     setShowMesurement(true);
  //     setCustomHit(hit);
  //   }
  // }

  const selectCustomUnit = () => {
    setShowMesurement(false);
    // addMesurment({ ...customHit }, customUnit.value);
  }

  const getUnitList = (type: any) => {
    switch (type) {
      case 'beer':
      case 'cider':
      case 'premium beer':
      case 'craft beer':
        return unitList.beer;
      case 'whisky':
        return unitList.shot;
      default:
        return [];
    }
  }

  const getIconMap = (elem: string) => {
    return `/assets/iconset/icon-${elem}.svg`
  }

  const Hit: React.FC<{ hit: any }> = ({ hit }) => (
    <IonItem className={'type-' + hit.type.replace(' ', '-').toLowerCase()} key={hit.drinkId}>
      <IonLabel>
        <h3>{hit.name}</h3>
        <p>{hit.type} {(hit.ABV !== 'null') ? new Intl.NumberFormat('en-GB', { style: 'percent', maximumFractionDigits: 2 }).format(hit.ABV) : null}</p>
        <IonButtons>
          {
            (hit.type.toLowerCase()) ? getUnitList(hit.type.toLowerCase()).map(unit => {
              return <IonButton key={unit.name} slot="end" color="primary" onClick={() => addMesurment(hit, unit.value)}>
                <IonIcon src={getIconMap(unit.name)}></IonIcon>
              </IonButton>;
            }) : null
          }
          {/* <IonButton slot="end" color="secondary" onClick={() => customMesurementHit(hit)}>{(customUnit.value) ? customUnit.value + '' + customUnit.type : 'Egyedi'}</IonButton> */}
        </IonButtons>
      </IonLabel>
    </IonItem>
  );

  return (
    <IonContent>
      {/* <IonSegment onIonChange={e => updateSegment(e.detail.value)} value={searchType}>
        <IonSegmentButton value="kereso">
          Kereso
          </IonSegmentButton>
        <IonSegmentButton value="egyedi">
          Egyedi
          </IonSegmentButton>
      </IonSegment> */}
      <InstantSearch
        searchClient={searchClient}
        indexName="dev2_DRINKS"
        stalledSearchDelay={500}>
        <CustomSearchBox hideModal={hideModal} />

        <IonList className={"list-overflow"}>
          <IonListHeader>
            <Stats />
          </IonListHeader>
          <Hits hitComponent={Hit} />
        </IonList>
      </InstantSearch>
      <IonPopover isOpen={showMesurement}
        onDidDismiss={e => setShowMesurement(false)}>
        <IonList>
          <IonItem>
            <IonInput value={customUnit.value} onIonChange={(e: any) => setCustomUnit({ ...customUnit, value: Number(e.detail.value)! })} type="number" placeholder="pl.: 330"></IonInput>
            <IonSelect interface="popover" value={customUnit.type} onIonChange={(e: any) => setCustomUnit({ ...customUnit, type: e.detail.value! })} slot="end" okText="Kivalaszt" cancelText="Megsem">
              <IonSelectOption value="ml">ml</IonSelectOption>
              <IonSelectOption value="dl">dl</IonSelectOption>
              <IonSelectOption value="oz">oz</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonButtons>
            <IonButton onClick={() => selectCustomUnit()}>Hozzaad</IonButton>
            <IonButton onClick={() => setShowMesurement(false)} fill="outline">Megsem</IonButton>
          </IonButtons>
        </IonList>
      </IonPopover>
    </IonContent>
  )
}

const CustomSearchBox = connectSearchBox(SearchBoxCustom);

export default Search;

