import React from 'react';
import {
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
} from '@ionic/react';
import './Home.css';
import { useState } from 'react';

import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import { addDrink, removeDrink } from '../data/user/user.actions';
import DrinkList from '../components/DrinkList';
import ProgressCard from '../components/ProgressCard';
import SummaryCard from '../components/SummaryCard';
// import { setIsLoggedInData } from '../data/dataApi';
import Navigation from '../components/Navigation';


interface OwnProps extends RouteComponentProps { }

interface StateProps {
  drinks?: any[];
  profile?: any;
}

interface DispatchProps {
  addDrink: typeof addDrink;
  removeDrink: typeof removeDrink;
}

interface HomeProps extends OwnProps, StateProps, DispatchProps { }

const Home: React.FC<HomeProps> = ({ history, drinks, profile, addDrink, removeDrink }) => {

  // const [showModal, setShowModal] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);

  // const showDrinkSearch = () => {
  //   Object.keys(profile).forEach((key) => (!profile[key]) && delete profile[key]);;
  //   if (['height', 'weight', 'age', 'gender'].every(key => Object.keys(profile).includes(key))) {
  //     setShowModal(true);
  //   } else {
  //     setShowAlert1(true);
  //   }
  // }

  // useEffect(() => {
  //   setIsLoggedInData(true);
  // }, [profile]);

  return (
    <>
      <IonPage>

        <IonContent>
          <IonGrid>
            <IonRow className={'max-height'}>
              <IonCol className={'max-height'} size="12" sizeSm="6" offsetSm="3" >
                <div className={"ad-spacer"}></div>
                <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} value="allapot">
                  <IonSegmentButton value="allapot">
                    <IonLabel>Allapot</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                {/* // Drink List */}
                <DrinkList items={drinks} limit={drinks?.length || 1} addAgain={addDrink} removeItem={removeDrink} />
                {/* // Drink List end */}
                <div className={"spacer"}></div>


                <IonRow>

                  {/* // progress card */}
                  <IonCol>
                    <ProgressCard />
                  </IonCol>
                  {/* // progress card end */}
                  <IonCol>
                    {/* // Summary card */}
                    <SummaryCard drinks={drinks} />
                    {/* // Summary card end */}

                  </IonCol>
                </IonRow>
                {/* <IonRow>
                  <IonCol>
                    <Location />
                  </IonCol>
                </IonRow> */}

              </IonCol>
            </IonRow>

          </IonGrid>
        </IonContent>
        <Navigation addDrink={addDrink} removeDrink={removeDrink} />
      </IonPage>

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        header={'Hianyzo adatok!'}
        message={'Toltsd ki a profilod hogy akkuratusan tudjunk szamolni!'}
        buttons={[
          {
            text: 'Megsem',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Rendben',
            handler: () => {
              history.push('/beallitas');
            }
          }
        ]}
      />
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    drinks: state.user.drinks,
    profile: state.user
  }),
  mapDispatchToProps: {
    addDrink,
    removeDrink,
  },
  component: Home
})
