import React, { useState } from 'react';
import { IonContent, IonButton, IonPage, IonIcon, IonLabel, IonGrid, IonRow, IonCol, IonList, IonItem, IonToggle, IonAlert, IonInput, IonSegment, IonSegmentButton, IonItemGroup, IonCard } from '@ionic/react';
import { moonOutline } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';
import { setUsername, clearAll, setUserHeight, setUserWeight, setUserAge, setUserGender, addDrink, removeDrink } from '../data/user/user.actions';
import { setDarkMode } from '../data/user/user.actions';
import './Settings.css';
import Navigation from '../components/Navigation';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  username?: string;
  darkMode: boolean;
  height?: number;
  weight?: number;
  age?: number;
  gender?: string;
}

interface DispatchProps {
  setUsername: typeof setUsername;
  setDarkMode: typeof setDarkMode;
  setUserHeight: typeof setUserHeight;
  setUserWeight: typeof setUserWeight;
  setUserAge: typeof setUserAge;
  setUserGender: typeof setUserGender;
  clearAll: typeof clearAll;
  addDrink: typeof addDrink;
  removeDrink: typeof removeDrink;
}

interface AccountProps extends OwnProps, StateProps, DispatchProps { }

const Settings: React.FC<AccountProps> = ({
  username, setUsername,
  darkMode, setDarkMode,
  height, setUserHeight,
  weight, setUserWeight,
  age, setUserAge,
  gender, setUserGender,
  clearAll,
  addDrink,
  removeDrink }) => {

  const [showAlert, setShowAlert] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [section, setSection] = useState('settings');

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeSm="6" offsetSm="3" >

              <IonSegment onIonChange={e => setSection(e.detail.value!)} value={section}>
                <IonSegmentButton value="settings">
                  <IonLabel>Settings</IonLabel>
                </IonSegmentButton>
                {/* <IonSegmentButton value="account">
                  <IonLabel>Account</IonLabel>
                </IonSegmentButton> */}
              </IonSegment>
              <div className="ion-padding-top ion-text-center">
                {(section === 'settings') ? <IonCard>
                  <IonList inset>
                    <IonItemGroup>
                      <IonItem>
                        <IonLabel position="floating">Becenev</IonLabel>
                        <IonInput value={username} type="text" placeholder="pl: Lajos ba" debounce={800} onIonChange={e => setUsername(e.detail.value!)}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Magassag</IonLabel>
                        <IonInput required value={height} type="number" placeholder="pl: 160cm" debounce={800} onIonChange={e => setUserHeight(parseInt(e.detail.value!, 10))}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Suly</IonLabel>
                        <IonInput required value={weight} type="number" placeholder="pl: 80kg" debounce={800} onIonChange={e => setUserWeight(parseInt(e.detail.value!, 10))}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Korod</IonLabel>
                        <IonInput required value={age} type="number" min="18" placeholder="pl: 23" debounce={800} onIonChange={e => setUserAge(parseInt(e.detail.value!, 10))}></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonSegment onIonChange={e => setUserGender(e.detail.value!)} value={gender}>
                          <IonSegmentButton value="man">
                            <IonLabel>Ferfi</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton value="woman">
                            <IonLabel>No</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton value="na">
                            <IonLabel>Egyeb</IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                      </IonItem>
                      <IonItem>
                        <IonIcon slot="start" icon={moonOutline}></IonIcon>
                        <IonLabel>Dark Mode</IonLabel>
                        <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
                      </IonItem>
                    </IonItemGroup>
                  </IonList>
                </IonCard> : null}
                {(section === 'account') ?
                  <IonCard>
                    [USER AUTH]
                  </IonCard>
                  : null}
                <IonButton color="danger" onClick={() => setShowAlert3(true)}>Adatok torlese</IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonAlert
        isOpen={showAlert3}
        onDidDismiss={() => setShowAlert3(false)}
        header={'Delete all settings and data!'}
        message={'Are you sure?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Delete',
            handler: () => {
              clearAll()
            }
          }
        ]}
      />
      <IonAlert
        isOpen={showAlert}
        header="Change Username"
        buttons={[
          'Cancel',
          {
            text: 'Ok',
            handler: (data) => {
              setUsername(data.username);
            }
          }
        ]}
        inputs={[
          {
            type: 'text',
            name: 'username',
            value: username,
            placeholder: 'username'
          }
        ]}
        onDidDismiss={() => setShowAlert(false)}
      />
      <Navigation addDrink={addDrink} removeDrink={removeDrink} />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    username: state.user.username,
    darkMode: state.user.darkMode,
    height: state.user.height,
    weight: state.user.weight,
    age: state.user.age,
    gender: state.user.gender,
  }),
  mapDispatchToProps: {
    setUsername,
    setDarkMode,
    setUserHeight,
    setUserWeight,
    setUserAge,
    setUserGender,
    clearAll,
    addDrink,
    removeDrink,
  },
  component: Settings
})
